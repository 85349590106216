import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=04f4c494&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports