<template>
  <div v-if="stock" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeStock"
      @cancelAction="cancelStockChanges"
      @deleteAction="deleteStock"
      @saveAction="updateStock"
    />
    <br>
    <StockShow
      v-if="!isEditing"
      :stock="stock"
      :showPriceAndSum="showPriceAndSum"
    />
    <StockEdit
      v-else
      :stock="stock"
      :showPriceAndSum="showPriceAndSum"
    />
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import StockApi from '../api/stock'
import deepAssign from 'assign-deep'
import StockShow from './Show'
import StockEdit from './Edit'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'StockPage',
  components: {
    StockEdit,
    StockShow,
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isEditing: false,
      stock: null,
      prevStock: null,
      isUpdating: false
    }
  },
  computed: {
    ...mapGetters('configs/configs', [
      'showPriceAndSum'
    ])
  },
  watch: {
    stock: {
      handler() {
        if (this.stock) {
          this.setTitle([
            {
              title: 'Объекты',
              url: '/facility'
            }, {
              title: this.stock.facility.name,
              url: `/facility/${this.stock.facility.id}`
            }, {
              title: `${this.stock.title}`
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.loadConfigs()
  },
  mounted () {
    this.loadStock()
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadStock () {
      StockApi.getItem({
        id: this.id,
        queryWith: ['facility', 'stockSum', 'supplier']
      })
        .then(response => {
          if (!response) {
            return
          }
          this.stock = response
        })
    },
    changeStock () {
      this.prevStock = deepAssign({}, this.stock)
      this.isEditing = true
    },
    deleteStock () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить элемент со склада?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          StockApi.removeItem(this.stock.id)
            .then(() => {
              this.$router.push('/facility')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelStockChanges () {
      this.stock = this.prevStock
      this.isEditing = false
    },
    updateStock () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      StockApi.updateItem(this.stock)
        .then(stock => {
          this.stock = { ...this.stock, ...stock.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>
