<template>
  <div class="page">
    <HeaderTitle />
    <SupplierEdit
      :supplier="supplier"
    />
    <button class="btn btn-primary float-right" @click="createSupplier()">Добавить</button>
    <router-link to="/supplier" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import SupplierApi from '../api/supplier'
import {mapActions} from 'vuex'
import SupplierEdit from './Edit'

export default {
  name: 'SupplierCreate',
  components: {SupplierEdit},
  data () {
    return {
      isCreating: false,
      supplier: {}
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Поставщики',
        url: '/supplier'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createSupplier () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      SupplierApi.createItem(this.supplier)
        .then(supplier => {
          this.isCreating = false
          this.$router.push(`/supplier/${supplier.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
