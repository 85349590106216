import moduleTemplate from '@/store/moduleTemplate'
import stock from '../api/stock'

const template = moduleTemplate(stock)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText',
  changeQuantity: 'changeQuantity'
}
template.mutations = {
  ...template.mutations,
  [template.types.loadItems](state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount

    items.map(stock => updateLabel(stock))
    state.items = items
  },
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  },
  [template.types.changeQuantity] (state, {stockId, delta}) {
    const stockIndex = state.items.findIndex(item => item.id === stockId)
    if (stockIndex === -1) {
      return
    }
    state.items[stockIndex].quantity = parseFloat(state.items[stockIndex].quantity) + parseFloat(delta)
    updateLabel(state.items[stockIndex])
  }
}
template.state = {
  ...template.state,
  searchText: '',
  expandAllItems: ['facility', 'stockSum', 'supplier']
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  },
  changeQuantity({ commit }, {stockId, delta}) {
    commit(template.types.changeQuantity, {stockId, delta})
  }
}

/**
 * Изменение label у расходника
 *
 * @param {object} stock Расходник
 * @returns {object} Расходник с обновленным label
 */
function updateLabel (stock) {
  if (stock.date) {
    stock.label = stock.title + ' от ' + (new Date(stock.date)).toLocaleDateString() + ' (max ' + stock.quantity + ')'
  } else {
    stock.label = stock.title + ' (max ' + stock.quantity + ')'
  }
  return stock
}

export default template
