<template>
  <div v-if="service" class="page page-edit-serve">
    <div class="d-flex justify-content-end">
      <router-link class="btn btn-secondary mr-2" :to="`/maintenance/car/${carId}`">Отмена</router-link>
      <button class="btn btn-primary" @click="updateServe()">Сохранить</button>
    </div>
    <HeaderTitle />
    <div class="form-group">
      <label>Дата/время обслуживания</label>
      <input
        v-model="datetimeService"
        type="datetime-local"
        class="form-control"
        aria-describedby="emailHelp"
        placeholder="Введите дату/время обслуживания"
        :max="now()"
      >
    </div>
    <div class="form-group">
      <label>Фактические показания одометра</label>
      <input
        v-model="service.total_odometer_stamp"
        type="number"
        class="form-control"
        aria-describedby="emailHelp"
        placeholder="Введите значение одометра"
      >
    </div>
    <div role="button" class="d-inline-block" @click="showWorks = !showWorks">
      <span>Выполненные работы</span>
      <font-awesome-icon
        :icon="showWorks ? 'chevron-up' : 'chevron-down'"
        class="text-secondary"
        style="margin-left: 10px"
      />
    </div>
    <div v-if="showWorks" class="d-flex flex-column">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по имени и интервалу"
          aria-label="Поиск по имени и интервалу"
          :value="searchText"
          @input="searchServiceWork"
        >
      </div>
    </div>
    <div v-if="showWorks" class="draggable-cards-wrapper">
      <DraggableTree
        ref="tree"
        class="tree4"
        :data="works"
        :indent="30"
        :space="0"
      >
        <div slot-scope="{data, store}">
          <template v-if="!data.isDragPlaceHolder">
            <div class="row" style="margin-left: 0">
              <div
                class="col row text-dark py-2"
              >
                <div class="col row">
                  <font-awesome-icon
                    v-if="data.children && data.children.length"
                    :icon="data.open ? 'chevron-up' : 'chevron-down'"
                    class="mr-2"
                    :style="{
                      color: 'gray',
                      'margin-left': 'auto',
                      cursor: 'pointer'
                    }"
                    @click="openChildrenList(data, store)"
                  />
                  <input
                    :id="`checkbox${data.id}`"
                    :ref="`checkbox${data.id}`"
                    v-model="data.selected"
                    type="checkbox"
                    class="mr-2"
                    @change="childrenCheckAll(data)"
                  >
                  <div class="col" @click="openChildrenList(data, store)">
                    <TextHighlight :queries="searchText">
                      {{ data.name }}
                    </TextHighlight>
                    <TextHighlight
                      class="badge"
                      style="line-height: unset"
                      :queries="searchText"
                    >
                      {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                    </TextHighlight>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </DraggableTree>
    </div>
    <br>
    <StockItemsEdit :added-stocks="addedStocks" />
    <br>
    <ConsumableEdit :consumable="service.consumable" />
    <br>
    <div class="form-group">
      <label>Прочие работы</label>
      <textarea v-model="service.notes" class="form-control" rows="6" />
    </div>
    <br>
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Рекомендации по ремонту</label>
      <textarea
        id="exampleFormControlTextarea1"
        v-model="service.recommendations"
        class="form-control"
        rows="6"
      />
    </div>
    <div>
      <h4>История изменений</h4>
      <div v-for="log in service.logs" :key="log.id">
        <div><b>Изменил:</b> {{ log.fullName }}</div>
        <div><b>Дата изменения:</b> {{ log.updated_at | formatDateTime }}</div>
        <div><b>Изменения:</b></div>
        <div class="ml-3">
          <div v-for="change in JSON.parse(log.changes)" :key="log.id + change.attribute_name">
            <b>{{ change.attribute_title }}</b>
            <div>
              <div v-if="change.attribute_name == 'stocks'" class="d-flex">
                <div class="change-box">
                  <div v-for="stock in change.oldValue" :key="stock.id">
                    • {{ stock.title }}
                  </div>
                </div>
                <div class="change-separator">
                  <font-awesome-icon icon="long-arrow-alt-right" />
                </div>
                <div class="change-box">
                  <div v-for="stock in change.newValue" :key="stock.id">
                    • {{ stock.title }}
                  </div>
                </div>
              </div>
              <div v-else-if="change.attribute_name == 'serviceWorks'" class="d-flex">
                <div class="change-box">
                  <div v-for="work in change.oldValue" :key="work.id">
                    • {{ work.name }}
                  </div>
                </div>
                <div class="change-separator">
                  <font-awesome-icon icon="long-arrow-alt-right" />
                </div>
                <div class="change-box">
                  <div v-for="work in change.newValue" :key="work.id">
                    • {{ work.name }}
                  </div>
                </div>
              </div>
              <div v-else class="d-flex">
                <div class="change-box">{{ change.oldValue }}</div>
                <div class="change-separator">
                  <font-awesome-icon icon="long-arrow-alt-right" />
                </div>
                <div class="change-box">{{ change.newValue }}</div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import userApi from '@/api/user'
import serviceApi from '../../api/service'
import { djsMixin } from '@/mixins/djsMixin'
import dayjs from 'dayjs'
import StockItemsEdit from '@/modules/maintenance/components/StockItemsEdit'
import ConsumableEdit from '@/modules/maintenance/components/ConsumableEdit'

export default {
  name: 'EditService',
  components: {ConsumableEdit, StockItemsEdit},
  mixins: [djsMixin],
  props: {
    carId: {
      type: String,
      default: '0'
    },
    serviceId: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      service: null,
      addedStocks: [],
      worksSelected: [],
      interval: null,
      showWorks: true
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      car (state) {
        return state.items.find(el => el.id == this.carId)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('maintenance/serviceWork', {
      worksState: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading,
      searchText: state => state.searchText
    }),
    datetimeService: {
      get () {
        return dayjs(this.service.datetime, 'YYYY-MM-DDTHH:mm:ssZZ').format('YYYY-MM-DDTHH:mm')
      },
      set (dateTime) {
        this.service.datetime = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    works () {
      if (this.worksState.length <= 0) {
        return []
      }
      if (this.worksSelected.length <= 0) {
        return JSON.parse(JSON.stringify(this.worksState))
      }
      this.updateSelected(this.worksState)
      return JSON.parse(JSON.stringify(this.worksState))
    }
  },
  mounted () {
    this.loadItems({sort: 'sort'})
      .then(() => {
        this.loadCar({ id: this.carId })
        this.setTitle([
          {
            title: 'Тех. обслуживание',
            url: '/maintenance'
          }, {
            title: 'Изменить'
          }
        ])
        serviceApi.getItem({
          id: this.serviceId,
          queryWith: ['serviceWorks', 'stocks', 'logs']
        }).then(service => {
          this.service = service
          this.addedStocks = service.stocks
          this.initiateWorksSelected()
          if (!this.interval) {
            this.loadWithSearch()
          }
        })
      })
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('taxi/cars', {
      loadCar: 'loadItem'
    }),
    ...mapActions('maintenance/serviceWork', [
      'loadItems',
      'abortLoading',
      'setSearch',
      'setSearchText'
  ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    initiateWorksSelected () {
      this.worksSelected = this.service.serviceWorks.map(sw => sw.id)
    },
    openChildrenList (data, store) {
      if (!data.children || !data.children.length) {
        data.selected = !data.selected
        this.childrenCheckAll(data)
      }
      store.toggleOpen(data)
    },
    childrenCheckAll (data) {
      let value = data.selected
      if (value) {
        this.worksSelected.push(data.id)
      } else {
        this.worksSelected = this.worksSelected.filter(id => id !== data.id)
      }
      data.children.forEach(child => {
        child.selected = value
        this.childrenCheckAll(child)
      })
      this.updateSelected(this.worksState)
    },
    updateSelected (array) {
      if (array.length <= 0) {
        return
      }
      array.forEach(el => {
        el.selected = this.worksSelected.includes(el.id)
        this.updateSelected(el.children)
      })
    },
    updateServe () {
      if (this.isCreating) return
      userApi
        .getCurrentUser()
        .then(user => {
          this.isCreating = true
          serviceApi.updateItem({
            ...this.service,
            datetime: this.toUTCtranslate(this.service.datetime),
            car_id: this.carId,
            author_id: user.id,
            worksRelatedIds: this.worksSelected,
            addedStocks: this.addedStocks
          })
            .then(() => {
              this.isCreating = false
              this.$router.push(`/maintenance/car/${this.carId}`)
            })
            .catch(err => {
              console.error(err)
              this.isCreating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({
          page: Number(this.page)
        }).then(() => {
          this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
        })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    searchServiceWork (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'name', value: e.target.value })
      this.setSearch({ key: 'interval', value: e.target.value })
    }
  }
}
</script>

<style scoped>
  .page-edit-serve {
    width: 98%;
    padding-bottom: 4rem;
  }
  ::v-deep .tree-node-inner:hover {
    cursor: pointer !important;
  }
  .change-box {
    padding: 10px;
    width: 350px;
    border-radius: 3px;
    border: 1px solid #cfcfcf;
  }
  .change-separator {
    align-self: center;
    padding: 10px;
    font-size: 18px;
  }
</style>
