<template>
  <div class="page">
    <HeaderTitle />
    <div>
      <table class="table borderless">
        <tbody>
          <tr>
            <td><label for="selectorFacilityFrom">Объект для списания</label></td>
            <td class="tdSelect">
              <VSelect
                id="selectorFacilityFrom"
                v-model="exchangeData.facilityFrom"
                class="facility-select"
                :options="facilities"
                label="name"
                placeholder
                required
              />
            </td>
          </tr>
          <tr>
            <td><label for="selectorStock">Предмет</label></td>
            <td class="tdSelect">
              <VSelect
                id="selectorStock"
                v-model="stock"
                class="stock-select"
                :options="filterStocks"
                label="label"
                placeholder
                required
              />
            </td>
          </tr>
          <tr>
            <td>Количество</td>
            <td>
              <input
                v-model="exchangeData.quantity"
                type="number"
                class="form-control"
                placeholder="Значение будет округлено до 2-х знаков после запятой"
                title="Количество"
                required
              >
            </td>
          </tr>
          <tr>
            <td><label for="selectorFacilityTo">Объект для начисления</label></td>
            <td class="tdSelect">
              <VSelect
                id="selectorFacilityTo"
                v-model="exchangeData.facilityTo"
                class="facility-select"
                :options="facilities"
                label="name"
                placeholder
                required
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-primary float-right" @click="exchange()">Подтвердить</button>
    <router-link to="/facility" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Swal from 'sweetalert2'
import StockApi from '@/modules/stock/api/stock'

export default {
  name: 'Exchange',
  data () {
    return {
      isCreating: false,
      exchangeData: {
        facilityFrom : null,
        facilityTo : null,
        quantity : null,
        stockId : null,
      },
      stock : null,
    }
  },
  computed: {
    filterStocks() {
      let sortByTitleAndDate = function (d1, d2) {return ((d1.title + d1.date).toLowerCase() > (d2.title + d2.date).toLowerCase()) ? 1 : -1}
      return this.stocks
        .filter(stock => {
          if (this.exchangeData.facilityFrom && this.exchangeData.facilityFrom.id) {
            return !stock.archived_at
              && stock.quantity > 0
              && stock.facility_id === this.exchangeData.facilityFrom.id
          }
          return !stock.archived_at && stock.quantity > 0
        })
        .sort(sortByTitleAndDate)
    },
    ...mapState('stock/stock', {
      stocks: state => state.items,
    }),
    ...mapState('facility/facility', {
      facilities: state => state.items,
    }),
  },
  watch: {
    'exchangeData.facilityFrom': {
      handler () {
        this.stock = null
      }
    },
    stock: {
      handler () {
        this.exchangeData.stockId = this.stock.id
      }
    },
  },
  mounted () {
    this.setTitle([
      {
        title: 'Объекты',
        url: '/facility'
      },
      {
        title: 'Списание между объектами'
      }
    ])
    this.loadStocks()
    this.loadItems()
  },
  methods: {
    exchange () {
      StockApi.exchange(this.stock, this.exchangeData).then((success) => {
        if (success) {
          this.$router.push(`/facility/${this.exchangeData.facilityTo.id}`)
        }
      })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка сервера',
            text: err.message
          })
        })
    },
    ...mapActions('stock/stock', {
      loadStocks: 'loadItems',
    }),
    ...mapActions('facility/facility', [
      'loadItems',
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
  },
}
</script>

<style scoped>
  .tdSelect {
    width: 60%;
  }
  .stock-select >>> li {
    width: fit-content;
    min-width: 100%;
  }
  .facility-select >>> li {
    width: fit-content;
    min-width: 100%;
  }
</style>
