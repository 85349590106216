import List from '@/modules/supplier/views/List.vue'
import Page from '@/modules/supplier/views/Page.vue'
import Create from '@/modules/supplier/views/Create.vue'

export default [
  {
    path: '/supplier',
    component: List,
    meta: {
      title: 'Поставщики',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/supplier/create',
    component: Create,
    props: true,
    meta: {
      title: 'Добавить поставщика',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/supplier/:supplierId',
    component: Page,
    props: true,
    meta: {
      title: 'Поставщик',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
