<template>
  <div v-if="supplier && configsLoaded" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeSupplier"
      @cancelAction="cancelSupplierChanges"
      @deleteAction="deleteSupplier"
      @saveAction="updateSupplier"
    />
    <a
      v-if="!isEditing"
      :href="'/stock/create?supplierId=' + supplier.id"
      class="input-group-btn btn btn-success m-1"
      style="float: right"
    >
      <font-awesome-icon class="text-white" icon="plus" />
      Добавить предмет
    </a>
    <StockList
      v-if="!isEditing"
      :supplier="supplier"
      :showPriceAndSum="showPriceAndSum"
    />
    <SupplierEdit
      v-else
      :supplier="supplier"
    />
  </div>
</template>

<script>

import SupplierEdit from '@/modules/supplier/views/Edit.vue'
import StockList from '@/modules/stock/views/List.vue'
import {mapActions, mapGetters, mapState} from 'vuex'
import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import SupplierApi from '../api/supplier'

export default {
  name: 'SupplierPage',
  components: {
    SupplierEdit,
    StockList,
  },
  props: {
    supplierId: {
      type: String,
      default: () => 0
    }
  },
  data () {
    return {
      isEditing: false,
      prevSupplier: null,
      isUpdating: false,
      supplier: null
    }
  },
  computed: {
    ...mapGetters('configs/configs', [
      'showPriceAndSum'
    ]),
    ...mapState('configs/configs', {
      configsLoaded: state => !state.isLoading
    })
  },
  watch: {
    supplier: {
      handler (supplier) {
        if (supplier) {
          this.setTitle([
            {
              title: 'Поставщики',
              url: '/supplier'
            }, {
              title: `${supplier.name}`
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    this.loadConfigs()
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadData () {
      SupplierApi.getItem({
        id: this.supplierId,
      })
        .then(response => {
          this.supplier = response
          if (!response) {
            return false
          }
        })
    },
    changeSupplier () {
      this.prevSupplier = deepAssign({}, this.supplier)
      this.isEditing = true
    },
    deleteSupplier () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить поставщика?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          SupplierApi.removeItem(this.supplier.id)
            .then(() => {
              this.$router.push('/supplier')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelSupplierChanges () {
      this.supplier = this.prevSupplier
      this.isEditing = false
    },
    updateSupplier () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      SupplierApi.updateItem(this.supplier)
        .then(supplier => {
          this.supplier = { ...this.supplier, ...supplier.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>
