
const result = [
  {
    text: 'Наблюдение',
    url: '/watch',
    icon: 'eye',
    subactiveRoutes: ['/devices'],
    sublinks: [
      {
        text: 'Карта',
        url: '/watch'
      }, {
        text: 'Устройства',
        url: '/devices'
      }
    ]
  }, {
    text: 'Аренда',
    url: '/rent',
    icon: 'retweet'
  }, {
    text: 'Фотоконтроль',
    url: '/photocontrol',
    icon: 'camera'
  }, {
    text: 'divider1',
    divider: true
  }, {
    text: 'Автомобили',
    url: '/cars',
    icon: 'car'
  }, {
    text: 'Водители',
    url: '/drivers',
    icon: ['fas', 'id-badge'],
    sublinks: [
      {
        text: 'Список',
        url: '/drivers'
      }
    ]
  }, {
    text: 'Организации',
    url: '/companies',
    icon: 'briefcase'
  }, {
    text: 'divider2',
    divider: true
  }, {
    text: 'Шаблоны',
    url: '/templates',
    icon: 'file-alt'
  }, {
    text: 'Пользователи',
    url: '/users',
    icon: 'user-lock'
  }, {
    text: 'Обратная связь',
    url: '/feedback',
    icon: 'bell',
    sublinks: [
      {
        text: 'Отправленные заявки',
        url: '/feedback'
      }, {
        text: 'Сообщения',
        url: '/feedback-admin'
      }
    ]
  }, {
    text: 'Инструкции',
    url: '/instruction',
    icon: 'book',
  }, {
    text: 'Логи',
    url: '/logs',
    icon: 'server'
  }, {
    text: 'Настройки',
    url: '/configs',
    icon: 'cogs',
  }
]

if (process.env.VUE_APP_MODULE_BLACKLIST === 'true') {
  const index = result.findIndex(e => e.text === 'Водители')
  result[index].sublinks.push({
    text: 'ЧС водителей',
    url: '/black-list'
  })
  result[index].subactiveRoutes = ['/drivers', '/black-list']
}

if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
  const index = result.findIndex(e => e.text === 'Шаблоны')
  result.splice(index + 1, 0, {
    text: 'Геозоны',
    url: '/map-restricted-area',
    icon: 'map-marked'
  })
}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'Топливо',
    url: '/fueling',
    icon: 'gas-pump',
    subactiveRoutes: ['/fuel-consumptions', '/fuel-types', '/car-driver'],
    sublinks: [
      {
        text: 'Заправки',
        url: '/fueling'
      }, {
        text: 'Расход',
        url: '/fuel-consumptions'
      }, {
        text: 'Виды топлива',
        url: '/fuel-types'
      }, {
        text: 'Текущий водитель',
        url: '/car-driver'
      }
    ]
  })
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'Тех. обслуживание',
    url: '/maintenance',
    sublinks: [
      {
        text: 'Список',
        url: '/maintenance'
      }, {
        text: 'Планирование',
        url: '/maintenance/planning'
      }, {
        text: 'Работы',
        url: '/maintenance/service-works'
      }, {
        text: 'Анализ ТО',
        url: '/maintenance/analysis'
      }
    ],
    icon: 'wrench'
  })

  result.splice(index + 1, 0, {
    text: 'Объекты',
    url: '/facility',
    subactiveRoutes: ['/supplier'],
    icon: 'archive',
    sublinks: [
      {
        text: 'Список',
        url: '/facility'
      }, {
        text: 'Поставщики',
        url: '/supplier'
      }
    ],
  })
}
if (process.env.VUE_APP_MODULE_FINES === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'Штрафы ГИБДД',
    url: '/fines',
    icon: 'money-bill-alt'
  })
}

if (process.env.VUE_APP_MODULE_ACCIDENT === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'ДТП',
    url: '/accidents',
    icon: 'car-crash'
  })
}

export default result
