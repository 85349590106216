/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'

export const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}
const url = '/api/waybill'
const template = APITemplate(url)
template.getItems = config => {
  const params = new URLSearchParams()
  params.append('id', config.id)
  params.append('startDateTime', config.startDateTime)
  params.append('endDateTime', config.endDateTime)
  return template.fetchGet(`${url}/get-statics?${params}`)
}

/**
 * Получить информацию об адресе
 * URL: https://photon.komoot.io
 * Используются следующие параметры запроса:
 * - q - адрес
 * Сервис возвращает информацию об адресе (координаты, тип помещения, почтовый индекс и тд)
 * @param {object} config Конфигурация с координатами
 * @returns {object} Response server.
 */
template.getAddressByCoords = config => {
  const request = '/api/waybill/get-address?' + new URLSearchParams({
    lat: config.lat,
    lon: config.lon
  })
    return fetch(request, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.errorHandler)
}
export default template
