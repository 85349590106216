import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faChevronDown,
  faChevronUp,
  faMapMarked,
  faMapMarkerAlt,
  faCircle,
  faExternalLinkAlt,
  faFileWord,
  faTrashAlt,
  faPlusSquare,
  faTimes,
  faPen,
  faCloudUploadAlt,
  faCheck,
  faUndo,
  faEye,
  faDownload,
  faBell,
  faUserCircle,
  faCog,
  faCar,
  faRetweet,
  faWrench,
  faFileAlt,
  faIdBadge,
  faBriefcase,
  faArrowsAltH,
  faUserLock,
  faFilePdf,
  faFile,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faParking,
  faRoute,
  faClock,
  faTachometerAlt,
  faRoad,
  faFlag,
  faFlagCheckered,
  faClipboardList,
  faArrowLeft,
  faSearchLocation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faTools,
  faSort,
  faPlus,
  faSortUp,
  faSortDown,
  faGripLines,
  faExclamation,
  faExclamationCircle,
  faFilter,
  faGasPump,
  faEdit,
  faStop,
  faFileDownload,
  faMoneyBillAlt,
  faCogs,
  faEquals,
  faIdCard,
  faCarCrash,
  faEnvelope,
  faFileArchive,
  faFileImage,
  faFileExcel,
  faCamera,
  faLongArrowAltRight,
  faBook,
  faBan,
  faAngleDoubleDown,
  faServer,
  faArchive,
  faTruck
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserLock)
library.add(faArrowsAltH)
library.add(faBriefcase)
library.add(faFileWord)
library.add(faTrashAlt)
library.add(faSearch)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faMapMarked)
library.add(faMapMarkerAlt)
library.add(faCircle)
library.add(faExternalLinkAlt)
library.add(faPlusSquare)
library.add(faTimes)
library.add(faPen)
library.add(faCloudUploadAlt)
library.add(faCheck)
library.add(faUndo)
library.add(faEye)
library.add(faDownload)
library.add(faBell)
library.add(faUserCircle)
library.add(faCog)
library.add(faCar)
library.add(faRetweet)
library.add(faGasPump)
library.add(faWrench)
library.add(faFileAlt)
library.add(faIdBadge)
library.add(faFilePdf)
library.add(faFile)
library.add(faSortAmountDownAlt)
library.add(faSortAmountUpAlt)
library.add(faParking)
library.add(faRoute)
library.add(faClock)
library.add(faTachometerAlt)
library.add(faRoad)
library.add(faFlag)
library.add(faFlagCheckered)
library.add(faClipboardList)
library.add(faArrowLeft)
library.add(faSearchLocation)
library.add(faAngleDoubleRight)
library.add(faAngleDoubleLeft)
library.add(faTools)
library.add(faSort)
library.add(faPlus)
library.add(faSortUp)
library.add(faSortDown)
library.add(faGripLines)
library.add(faExclamation)
library.add(faExclamationCircle)
library.add(faFilter)
library.add(faEdit)
library.add(faStop)
library.add(faFileDownload)
library.add(faMoneyBillAlt)
library.add(faCogs)
library.add(faIdCard)
library.add(faEquals)
library.add(faCarCrash)
library.add(faEnvelope)
library.add(faFileArchive)
library.add(faFileImage)
library.add(faFileExcel)
library.add(faCamera)
library.add(faLongArrowAltRight)
library.add(faBook)
library.add(faBan)
library.add(faAngleDoubleDown)
library.add(faServer)
library.add(faArchive)
library.add(faTruck)
Vue.component('font-awesome-icon', FontAwesomeIcon)
