<template>
  <div class="page">
    <ul v-if="configs.length > 0" class="nav nav-tabs">
      <li v-for="(log, key) in configs" :key="key" @click="activeTab = key">
        <a
          :class="key === 0 ? 'nav-link active' : 'nav-link' "
          data-toggle="tab"
          :href="'#' + log.title"
        >
          {{ log.title }}
        </a>
      </li>
    </ul>
    <span v-else>
      Файлы логов не найдены или пустая конфигурация
    </span>
    <div class="tab-content ">
      <div
        v-for="(log, key) in configs"
        :id="log.title"
        :key="key"
        :class="key === activeTab ? 'tab-pane fade p-3 show active' : 'tab-pane fade p-3'"
      >
        <LogViewer
          v-if="activeTab === key"
          :apiUrl="'/api/logs/get-task-logs?nameLog=' + log.path"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import LogViewer from '@/components/LogViewer.vue'

export default {
  name: 'Page',
  components: {LogViewer},
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    configs () {
      if (!this.logConfig) {
        return []
      }
      return this.logConfig.value.names ?? []
    },
    ...mapState('configs/configs', {
      logConfig: state => state.items.find(i => i.component_name == 'LogsOutputSettings')
    }),
  },
  beforeMount () {
    this.loadConfigs()
    this.setTitle([
      {
        title: 'Логи'
      }
    ])
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  },
}
</script>
