<template>
  <div>
    <label>Расходный материал с объекта</label>
    <div class="row align-items-end">
      <div class="col-4 d-none d-md-block">
        <label>Объект</label>
        <VSelect
          v-model="facility"
          class="facility-select"
          style="width: 100%"
          :options="facilities"
          label="name"
        />
      </div>
      <div class="col-12 mobile-table-view d-md-none">
        <label>Объект</label>
        <VSelect
          v-model="facility"
          class="facility-select"
          style="width: 100%"
          :options="facilities"
          label="name"
        />
      </div>
      <div class="col-5 d-none d-md-block">
        <label>Предмет</label>
        <VSelect
          v-model="addStock"
          class="stock-select"
          style="width: 100%"
          :options="filterStocks"
          label="label"
        />
      </div>
      <div class="col-12 mobile-table-view d-md-none">
        <label>Предмет</label>
        <VSelect
          v-model="addStock"
          class="stock-select"
          style="width: 100%"
          :options="filterStocks"
          label="label"
        />
      </div>
      <div class="col-2 d-none d-md-block">
        <label>Количество</label>
        <input
          v-model="quantity"
          type="number"
          class="form-control"
          placeholder="Округляется до 2-х знаков после запятой"
          title="Количество, округляется до 2-х знаков после запятой"
        >
      </div>
      <div class="col-10 mobile-table-view d-md-none">
        <label>Количество</label>
        <input
          v-model="quantity"
          type="number"
          class="form-control"
          placeholder="Округляется до 2-х знаков после запятой"
          title="Количество, округляется до 2-х знаков после запятой"
        >
      </div>
      <button class="btn btn-success col-auto" @click="pushStock">
        <font-awesome-icon class="text-white" icon="plus" />
      </button>
    </div>
    <table v-if="addedStocks.length" class="table w-100 mt-3 d-none d-md-table">
      <TableSort :sorts="sorts" />
      <tbody>
        <tr v-for="stock of addedStocks" :key="stock.id">
          <td>{{ stock.title }}</td>
          <td>{{ stock.quantity }}</td>
          <td class="d-flex justify-content-end">
            <button class="btn btn-danger col-auto" @click="removeStock(stock)">
              <font-awesome-icon class="text-white" icon="trash-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="addedStocks.length">
      <div v-for="stock of addedStocks" :key="stock.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td>Название</td>
              <td>{{ stock.title }}</td>
              <td class="mobile-remove-btn" rowspan="2">
                <button class="btn btn-danger col-auto" @click="removeStock(stock)">
                  <font-awesome-icon class="text-white" icon="trash-alt" />
                </button>
              </td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>{{ stock.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'StockItemsEdit',
  props: {
    addedStocks: {
      type: Array,
      default: Array
    },
  },
  data () {
    return {
      sorts: [
        {
          name: 'Название',
          storeName: 'stock-title',
          values: ['', 'title', '-title'],
          order: 0
        },
        {
          name: 'Количество',
          storeName: 'stock-quantity',
          values: ['', 'quantity', '-quantity'],
          order: 1
        },
        {
          name: '',
          storeName: '',
          values: ['', '', ''],
          disabled: true
        },
      ],
      addStock: null,
      quantity: null,
      facility: null
    }
  },
  computed: {
    filterStocks() {
      let sortByTitleAndDate = function (d1, d2) {return ((d1.title + d1.date).toLowerCase() > (d2.title + d2.date).toLowerCase()) ? 1 : -1}
      return this.stocks
        .filter(stock => {
          if (this.facility) {
            return !this.addedStocks.find(addedStock => addedStock.id === stock.id)
              && !stock.archived_at
              && stock.quantity > 0
              && stock.facility_id === this.facility.id
          }
          return !this.addedStocks.find(addedStock => addedStock.id === stock.id)
            && !stock.archived_at
            && stock.quantity > 0
        })
        .sort(sortByTitleAndDate)
    },
    ...mapState('stock/stock', {
      stocks: state => state.items,
    }),
    ...mapState('facility/facility', {
      facilities: state => state.items,
    }),
  },
  watch: {
    facility() {
      this.addStock = []
    },
  },
  mounted () {
    this.loadStocks()
    this.loadItems()
    this.facility = this.facilities[0] ?? []
  },
  methods: {
    pushStock () {
      if (!this.addStock) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Предмет не выбран!'
        })
        return false
      }
      this.quantity = parseFloat(this.quantity)
      if (!this.quantity || this.quantity <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не введено количество товара!'
        })
        return false
      }

      if (this.quantity > this.addStock.quantity) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Введеное количество товара превышает оставшееся на складе!'
        })
        return false
      }

      if (this.addStock && this.quantity > 0) {
        let stock = JSON.parse(JSON.stringify(this.addStock))
        stock.quantity = this.quantity
        this.addedStocks.push(stock)
        this.changeQuantity({stockId: stock.id, delta: -stock.quantity})
        this.addStock = null
        this.quantity = null
      }
    },
    removeStock (stock) {
      this.addedStocks.forEach((stockItem, index) => {
        if (stockItem.id === stock.id) {
          this.addedStocks.splice(index, 1)
          this.changeQuantity({stockId: stockItem.id, delta: stockItem.quantity})
          return
        }
      })
    },
    ...mapActions('facility/facility', [
      'loadItems',
    ]),
    ...mapActions('stock/stock', {
      loadStocks: 'loadItems',
      changeQuantity: 'changeQuantity'
    })
  }
}
</script>

<style scoped>
  .mobile-remove-btn {
    vertical-align: middle;
    text-align: center;
  }
  .stock-select >>> li {
    width: fit-content;
    min-width: 100%;
  }
  .facility-select >>> li {
    width: fit-content;
    min-width: 100%;
  }
</style>
